import useCurriculum from "./useCurriculum";
import { useUser } from "../contexts/user";

export default function useHasCurriculumAccess() {
  const { user } = useUser();
  const curriculum = useCurriculum();

  if (user.features.connectedPianoApi && !!curriculum) {
    // Once Piano is fully operational, the following should be removed
    // eslint-disable-next-line no-console
    console.log("not connected to Piano yet");
    return null;
  }

  return !!curriculum;
}
