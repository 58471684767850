import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useBadgesQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;

  const {
    data: badges,
    isLoading: isBadgesLoading,
    isError: badgesError,
  } = useQuery([QUERY_KEYS.BADGES], () => client.getBadges(), {
    staleTime: oneHour * 4,
    ...options,
  });

  return { badges, isBadgesLoading, badgesError };
}
