import React from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { usePathname } from "next/navigation";

import BugsnagClient from "../../utils/bugsnag";

import { useUser } from "../user";

const missingTpProvider = "You forgot to wrap your app in <PianoProvider>";

const PianoContext = React.createContext();

PianoContext.displayName = "PianoContext";

export function PianoProvider({ children }) {
  const isPianoReady =
    typeof window !== "undefined" &&
    window.tp &&
    !Array.isArray(window.tp) &&
    typeof window.tp === "object";
  const piano = isPianoReady ? window.tp : undefined;
  const pathname = usePathname();
  const { user } = useUser();

  React.useEffect(() => {
    if (piano) {
      piano.push([
        "setComposerHost",
        process.env.NEXT_PUBLIC_PIANO_COMPOSER_HOST,
      ]);
      piano.push(["setPianoIdUrl", process.env.NEXT_PUBLIC_PIANO_ID_URL]);
      piano.push(["setEndpoint", process.env.NEXT_PUBLIC_PIANO_ENDPOINT]);
      piano.push(["setStaticDomain", process.env.NEXT_PUBLIC_PIANO_CDN_DOMAIN]);
    }
  }, [piano]);

  React.useEffect(() => {
    if (piano && user.features.connectedPianoApi) {
      if (user.pianoAuthToken) {
        // If the user has a token set in back end, we need to push it to Piano
        // on every page navigation, that's why pathname is in the dependencies array
        piano.push(["setExternalJWT", user.pianoAuthToken]);
      } else if (piano.getExternalJWT()) {
        // if the user doesn't have a token set in back end, but we still
        // have it locally, we log the user out from Piano and clean the token
        piano.pianoId?.logout();
        piano.push(["setExternalJWT"]);
      }
    }
  }, [pathname, user, piano]);

  return (
    <PianoContext.Provider
      value={{
        piano,
        // TODO sc-82084: setPianoTags
      }}
    >
      <Script
        src={`${process.env.NEXT_PUBLIC_PIANO_SCRIPT_URL}?aid=${process.env.NEXT_PUBLIC_PIANO_APPLICATION_ID}`}
        onError={(event) => {
          BugsnagClient.notify({
            name: "Piano script failed to load",
            message: `${event.target.src} failed to load`,
          });
        }}
        strategy="afterInteractive"
      />
      {children}
    </PianoContext.Provider>
  );
}

export function usePiano() {
  const piano = React.useContext(PianoContext);
  if (!piano) {
    throw new Error(missingTpProvider);
  }
  return piano;
}

PianoProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

PianoProvider.defaultProps = {
  children: null,
};
